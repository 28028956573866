export default {
	namespaced: true,
	state: {
		userinfo: {}
	},
	mutations: {
		USER_INFO(state, info) {
			state.userinfo = info
		}
	},
	actions: {
		// 保存用户循序
		saveUserInfo({ commit,dispatch, },data){
		      commit('USER_INFO',data)
		  }
	}
};
