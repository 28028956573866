import Vue from 'vue'
import Vuex from 'vuex'
import userInfo	from './modules/user';
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
      userInfo
    }
})
export default store;
// export default new Vuex.Store({
//   state: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//     userInfo
//   }
// })
