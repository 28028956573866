import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
const includPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return includPush.call(this, location).catch(err => err)

}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue'),
    meta: {
      keepAlive: false
    }
  },
  {
    path: '/upload',
    name: 'Upload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/upload.vue'),
    meta: {
      keepAlive: true,
      color: 'red'
    }
  },
  {
    path: '/publicarticle',
    name: 'Publicarticle',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/publicarticle.vue'),
    meta: {
      keepAlive: true,
      color: 'red'
    }
  },
  {
    path: '/photolist',
    name: 'Photolist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/catlist/photolist.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/articlelist',
    name: 'Articlelist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/catlist/articlelist.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/articledetail',
    name: 'Articledetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/detail/articledetail.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/photodetail',
    name: 'Photodetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/detail/photodetail.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/videodetail',
    name: 'Videodetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/detail/videodetail.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/publicmedia',
    name: 'Publicmedia',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/publicmedia.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/mediadetail',
    name: 'Mediadetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/detail/mediadetail.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/publicvideo',
    name: 'Publicvideo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/publicvideo.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/videolist',
    name: 'Videolist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/catlist/videolist.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/medialist',
    name: 'Medialist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/catlist/medialist.vue'),
    meta: {
      keepAlive: true,
    }
  },
   {
    path: '/creatorlist',
    name: 'Creatorlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/creator/creatorlist.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/creatorcenter',
    name: 'Creatorcenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/creator/creatorcenter.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/fans',
    name: 'Fans',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/creator/fans.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/personalcenter',
    name: 'Personalcenter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/personalcenter.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/userworks',
    name: 'Userworks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/userworks.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/myworks',
    name: 'Myworks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/user/myworks.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/search.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/school',
    name: 'School',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/school.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/protocol',
    name: 'Protocol',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/protocol.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/test',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/test.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/itemlist',
    name: 'Itemlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/catlist/itemlist.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/2021_6',
    name: '2021_6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/2021/6/index.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/notice',
    name: 'notice',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/notice.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/noticeinfo',
    name: 'noticeinfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/noticedetail.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/adschool',
    name: 'adschool',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/adschool.vue'),
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/appdownload',
    name: 'appdownload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/appdownload.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/autodownload',
    name: 'autodownload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/autodownload.vue'),
    meta: {
      keepAlive: false,
    }
  },
  {
    path: '/activelist',
    name: 'activelist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/activity/activelist.vue'),
    meta: {
      keepAlive: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
