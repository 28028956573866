import Vue from 'vue'
import promise from 'es6-promise' 
import 'babel-polyfill'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css'
promise.polyfill() // 兼容 Promise
import App from './App.vue'
import router from './router'
import store from './store'
import VueLazyLoad from 'vue-lazyload'
import waterfall from "vue-waterfall2";
import VideoPlayer from 'vue-video-player'

import sock from "./assets/js/socket.js"
Vue.prototype.$sock = sock


import animated from 'animate.css';
Vue.use(animated)
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.use(waterfall);
import 'lib-flexible'
Vue.use(VueLazyLoad,{
  preLoad: 1,
  error:require('./assets/lazyload/err.png'),
  loading:require('./assets/lazyload/err.png'),
  attempt: 1,
})
Vue.use(ElementUI);
Vue.config.productionTip = false
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?0054223d83b95a6795f380b92dd742d7";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
// 过滤器
// 时间过滤器

Vue.filter('timeNew', function (val) {
  let minute = 1000 * 60;
  let hour = minute * 60;
  let day = hour * 24;
  let week = day * 7;
  let month = day * 30;
  let time1 = new Date().getTime(); //当前的时间戳
  // console.log(time1)
  let time2 = Date.parse(new Date(val)); //指定时间的时间戳
  // console.log(time2)
  let time = time1 - time2;
  let result = null;
  if (time < 0) {
    result = "刚刚";
  } else if (time / month >= 1) {
    result = parseInt(time / month) + "月前";
  } else if (time / week >= 1) {
    result = parseInt(time / week) + "周前";
  } else if (time / day >= 1) {
    result = parseInt(time / day) + "天前";
  } else if (time / hour >= 1) {
    result = parseInt(time / hour) + "小时前";
  } else if (time / minute >= 1) {
    result = parseInt(time / minute) + "分钟前";
  } else {
    result = "刚刚";
  }
  return result;
})

// 数字格式化
Vue.filter('numFomart',function(num){
        if (num >= 100000000) {
          num = Math.round(num / 10000000) / 10 + '亿'
      } else if (num >= 10000) {
          num = Math.round(num / 1000) / 10 + '万'
      }
      return num;
})



// 转换播放时间
Vue.filter('transTime', function (time) {
  let duration = parseInt(time);
  let minute = parseInt(duration / 60)
    .toString()
    .padStart(2, "0");
  let sec = (duration % 60).toString().padStart(2, "0");
  return `${minute}:${sec}`;
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
