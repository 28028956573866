

export const NewArealist = (area) =>{
    let b =  [
        {
        "id": 1,
        "pid": 0,
        "name": "郑州",
        "level": 1,
        "data": [{
            "id": 19,
            "pid": 1,
            "name": "中原区",
            "level": 2
        },
        {
            "id": 176,
            "pid": 1,
            "name": "经济技术开发区",
            "level": 2
        }, 
        {
            "id": 177,
            "pid": 1,
            "name": "郑东新区",
            "level": 2
        },{
            "id": 20,
            "pid": 1,
            "name": "二七区",
            "level": 2
        }, {
            "id": 21,
            "pid": 1,
            "name": "管城回族区",
            "level": 2
        }, {
            "id": 22,
            "pid": 1,
            "name": "金水区",
            "level": 2
        }, {
            "id": 23,
            "pid": 1,
            "name": "上街区",
            "level": 2
        }, {
            "id": 24,
            "pid": 1,
            "name": "惠济区",
            "level": 2
        }, {
            "id": 25,
            "pid": 1,
            "name": "中牟县",
            "level": 2
        }, {
            "id": 26,
            "pid": 1,
            "name": "巩义市",
            "level": 2
        }, {
            "id": 27,
            "pid": 1,
            "name": "荥阳市",
            "level": 2
        }, {
            "id": 28,
            "pid": 1,
            "name": "新密市",
            "level": 2
        }, {
            "id": 29,
            "pid": 1,
            "name": "新郑市",
            "level": 2
        }, {
            "id": 30,
            "pid": 1,
            "name": "登封市",
            "level": 2
        }]
    }, {
        "id": 2,
        "pid": 0,
        "name": "开封",
        "level": 1,
        "data": [{
            "id": 31,
            "pid": 2,
            "name": "龙亭区",
            "level": 2
        }, {
            "id": 32,
            "pid": 2,
            "name": "顺河回族区",
            "level": 2
        }, {
            "id": 33,
            "pid": 2,
            "name": "鼓楼区",
            "level": 2
        }, {
            "id": 34,
            "pid": 2,
            "name": "禹王台区",
            "level": 2
        }, {
            "id": 35,
            "pid": 2,
            "name": "祥符区",
            "level": 2
        }, {
            "id": 36,
            "pid": 2,
            "name": "杞县",
            "level": 2
        }, {
            "id": 37,
            "pid": 2,
            "name": "通许县",
            "level": 2
        }, {
            "id": 38,
            "pid": 2,
            "name": "尉氏县",
            "level": 2
        }, {
            "id": 39,
            "pid": 2,
            "name": "兰考县",
            "level": 2
        }]
    }, {
        "id": 3,
        "pid": 0,
        "name": "洛阳",
        "level": 1,
        "data": [{
            "id": 40,
            "pid": 3,
            "name": "老城区",
            "level": 2
        }, {
            "id": 41,
            "pid": 3,
            "name": "西工区",
            "level": 2
        }, {
            "id": 42,
            "pid": 3,
            "name": "瀍河回族区",
            "level": 2
        }, {
            "id": 43,
            "pid": 3,
            "name": "涧西区",
            "level": 2
        }, {
            "id": 44,
            "pid": 3,
            "name": "吉利区",
            "level": 2
        }, {
            "id": 45,
            "pid": 3,
            "name": "洛龙区",
            "level": 2
        }, {
            "id": 46,
            "pid": 3,
            "name": "孟津县",
            "level": 2
        }, {
            "id": 47,
            "pid": 3,
            "name": "新安县",
            "level": 2
        }, {
            "id": 48,
            "pid": 3,
            "name": "栾川县",
            "level": 2
        }, {
            "id": 49,
            "pid": 3,
            "name": "嵩县",
            "level": 2
        }, {
            "id": 50,
            "pid": 3,
            "name": "汝阳县",
            "level": 2
        }, {
            "id": 51,
            "pid": 3,
            "name": "宜阳县",
            "level": 2
        }, {
            "id": 52,
            "pid": 3,
            "name": "洛宁县",
            "level": 2
        }, {
            "id": 53,
            "pid": 3,
            "name": "伊川县",
            "level": 2
        }, {
            "id": 54,
            "pid": 3,
            "name": "偃师市",
            "level": 2
        }]
    }, {
        "id": 4,
        "pid": 0,
        "name": "平顶山",
        "level": 1,
        "data": [{
            "id": 55,
            "pid": 4,
            "name": "新华区",
            "level": 2
        }, {
            "id": 56,
            "pid": 4,
            "name": "卫东区",
            "level": 2
        }, {
            "id": 57,
            "pid": 4,
            "name": "石龙区",
            "level": 2
        }, {
            "id": 58,
            "pid": 4,
            "name": "湛河区",
            "level": 2
        }, {
            "id": 59,
            "pid": 4,
            "name": "宝丰县",
            "level": 2
        }, {
            "id": 60,
            "pid": 4,
            "name": "叶县",
            "level": 2
        }, {
            "id": 61,
            "pid": 4,
            "name": "鲁山县",
            "level": 2
        }, {
            "id": 62,
            "pid": 4,
            "name": "郏县",
            "level": 2
        }, {
            "id": 63,
            "pid": 4,
            "name": "舞钢市",
            "level": 2
        }, {
            "id": 64,
            "pid": 4,
            "name": "汝州市",
            "level": 2
        }]
    }, {
        "id": 5,
        "pid": 0,
        "name": "焦作",
        "level": 1,
        "data": [{
            "id": 91,
            "pid": 5,
            "name": "解放区",
            "level": 2
        }, {
            "id": 92,
            "pid": 5,
            "name": "中站区",
            "level": 2
        }, {
            "id": 93,
            "pid": 5,
            "name": "马村区",
            "level": 2
        }, {
            "id": 94,
            "pid": 5,
            "name": "山阳区",
            "level": 2
        }, {
            "id": 95,
            "pid": 5,
            "name": "修武县",
            "level": 2
        }, {
            "id": 96,
            "pid": 5,
            "name": "博爱县",
            "level": 2
        }, {
            "id": 97,
            "pid": 5,
            "name": "武陟县",
            "level": 2
        }, {
            "id": 98,
            "pid": 5,
            "name": "温县",
            "level": 2
        }, {
            "id": 99,
            "pid": 5,
            "name": "沁阳市",
            "level": 2
        }, {
            "id": 100,
            "pid": 5,
            "name": "孟州市",
            "level": 2
        }]
    }, {
        "id": 6,
        "pid": 0,
        "name": "鹤壁",
        "level": 1,
        "data": [{
            "id": 74,
            "pid": 6,
            "name": "鹤山区",
            "level": 2
        }, {
            "id": 75,
            "pid": 6,
            "name": "山城区",
            "level": 2
        }, {
            "id": 76,
            "pid": 6,
            "name": "淇滨区",
            "level": 2
        }, {
            "id": 77,
            "pid": 6,
            "name": "浚县",
            "level": 2
        }, {
            "id": 78,
            "pid": 6,
            "name": "淇县",
            "level": 2
        }]
    }, {
        "id": 7,
        "pid": 0,
        "name": "新乡",
        "level": 1,
        "data": [{
            "id": 79,
            "pid": 7,
            "name": "红旗区",
            "level": 2
        }, {
            "id": 80,
            "pid": 7,
            "name": "卫滨区",
            "level": 2
        }, {
            "id": 81,
            "pid": 7,
            "name": "凤泉区",
            "level": 2
        }, {
            "id": 82,
            "pid": 7,
            "name": "牧野区",
            "level": 2
        }, {
            "id": 83,
            "pid": 7,
            "name": "新乡县",
            "level": 2
        }, {
            "id": 84,
            "pid": 7,
            "name": "获嘉县",
            "level": 2
        }, {
            "id": 85,
            "pid": 7,
            "name": "原阳县",
            "level": 2
        }, {
            "id": 86,
            "pid": 7,
            "name": "延津县",
            "level": 2
        }, {
            "id": 87,
            "pid": 7,
            "name": "封丘县",
            "level": 2
        }, {
            "id": 88,
            "pid": 7,
            "name": "长垣县",
            "level": 2
        }, {
            "id": 89,
            "pid": 7,
            "name": "卫辉市",
            "level": 2
        }, {
            "id": 90,
            "pid": 7,
            "name": "辉县市",
            "level": 2
        }]
    }, {
        "id": 8,
        "pid": 0,
        "name": "安阳",
        "level": 1,
        "data": [{
            "id": 65,
            "pid": 8,
            "name": "文峰区",
            "level": 2
        }, {
            "id": 66,
            "pid": 8,
            "name": "北关区",
            "level": 2
        }, {
            "id": 67,
            "pid": 8,
            "name": "殷都区",
            "level": 2
        }, {
            "id": 68,
            "pid": 8,
            "name": "龙安区",
            "level": 2
        }, {
            "id": 69,
            "pid": 8,
            "name": "安阳县",
            "level": 2
        }, {
            "id": 70,
            "pid": 8,
            "name": "汤阴县",
            "level": 2
        }, {
            "id": 71,
            "pid": 8,
            "name": "滑县",
            "level": 2
        }, {
            "id": 72,
            "pid": 8,
            "name": "内黄县",
            "level": 2
        }, {
            "id": 73,
            "pid": 8,
            "name": "林州市",
            "level": 2
        }]
    }, {
        "id": 9,
        "pid": 0,
        "name": "濮阳",
        "level": 1,
        "data": [{
            "id": 101,
            "pid": 9,
            "name": "华龙区",
            "level": 2
        }, {
            "id": 102,
            "pid": 9,
            "name": "清丰县",
            "level": 2
        }, {
            "id": 103,
            "pid": 9,
            "name": "南乐县",
            "level": 2
        }, {
            "id": 104,
            "pid": 9,
            "name": "范县",
            "level": 2
        }, {
            "id": 105,
            "pid": 9,
            "name": "台前县",
            "level": 2
        }, {
            "id": 106,
            "pid": 9,
            "name": "濮阳县",
            "level": 2
        }]
    }, {
        "id": 10,
        "pid": 0,
        "name": "许昌",
        "level": 1,
        "data": [{
            "id": 107,
            "pid": 10,
            "name": "魏都区",
            "level": 2
        }, {
            "id": 108,
            "pid": 10,
            "name": "许昌县",
            "level": 2
        }, {
            "id": 109,
            "pid": 10,
            "name": "鄢陵县",
            "level": 2
        }, {
            "id": 110,
            "pid": 10,
            "name": "襄城县",
            "level": 2
        }, {
            "id": 111,
            "pid": 10,
            "name": "禹州市",
            "level": 2
        }, {
            "id": 112,
            "pid": 10,
            "name": "长葛市",
            "level": 2
        }]
    }, {
        "id": 11,
        "pid": 0,
        "name": "漯河",
        "level": 1,
        "data": [{
            "id": 113,
            "pid": 11,
            "name": "源汇区",
            "level": 2
        }, {
            "id": 114,
            "pid": 11,
            "name": "郾城区",
            "level": 2
        }, {
            "id": 115,
            "pid": 11,
            "name": "召陵区",
            "level": 2
        }, {
            "id": 116,
            "pid": 11,
            "name": "舞阳县",
            "level": 2
        }, {
            "id": 117,
            "pid": 11,
            "name": "临颍县",
            "level": 2
        }]
    }, {
        "id": 12,
        "pid": 0,
        "name": "三门峡",
        "level": 1,
        "data": [{
            "id": 118,
            "pid": 12,
            "name": "湖滨区",
            "level": 2
        }, {
            "id": 119,
            "pid": 12,
            "name": "陕州区",
            "level": 2
        }, {
            "id": 120,
            "pid": 12,
            "name": "渑池县",
            "level": 2
        }, {
            "id": 121,
            "pid": 12,
            "name": "卢氏县",
            "level": 2
        }, {
            "id": 122,
            "pid": 12,
            "name": "义马市",
            "level": 2
        }, {
            "id": 123,
            "pid": 12,
            "name": "灵宝市",
            "level": 2
        }]
    }, {
        "id": 13,
        "pid": 0,
        "name": "南阳",
        "level": 1,
        "data": [{
            "id": 124,
            "pid": 13,
            "name": "宛城区",
            "level": 2
        }, {
            "id": 125,
            "pid": 13,
            "name": "卧龙区",
            "level": 2
        }, {
            "id": 126,
            "pid": 13,
            "name": "南召县",
            "level": 2
        }, {
            "id": 127,
            "pid": 13,
            "name": "方城县",
            "level": 2
        }, {
            "id": 128,
            "pid": 13,
            "name": "西峡县",
            "level": 2
        }, {
            "id": 129,
            "pid": 13,
            "name": "镇平县",
            "level": 2
        }, {
            "id": 130,
            "pid": 13,
            "name": "内乡县",
            "level": 2
        }, {
            "id": 131,
            "pid": 13,
            "name": "淅川县",
            "level": 2
        }, {
            "id": 132,
            "pid": 13,
            "name": "社旗县",
            "level": 2
        }, {
            "id": 133,
            "pid": 13,
            "name": "唐河县",
            "level": 2
        }, {
            "id": 134,
            "pid": 13,
            "name": "新野县",
            "level": 2
        }, {
            "id": 135,
            "pid": 13,
            "name": "桐柏县",
            "level": 2
        }, {
            "id": 136,
            "pid": 13,
            "name": "邓州市",
            "level": 2
        }]
    }, {
        "id": 14,
        "pid": 0,
        "name": "信阳",
        "level": 1,
        "data": [{
            "id": 146,
            "pid": 14,
            "name": "浉河区",
            "level": 2
        }, {
            "id": 147,
            "pid": 14,
            "name": "平桥区",
            "level": 2
        }, {
            "id": 148,
            "pid": 14,
            "name": "罗山县",
            "level": 2
        }, {
            "id": 149,
            "pid": 14,
            "name": "光山县",
            "level": 2
        }, {
            "id": 150,
            "pid": 14,
            "name": "新县",
            "level": 2
        }, {
            "id": 151,
            "pid": 14,
            "name": "商城县",
            "level": 2
        }, {
            "id": 152,
            "pid": 14,
            "name": "固始县",
            "level": 2
        }, {
            "id": 153,
            "pid": 14,
            "name": "潢川县",
            "level": 2
        }, {
            "id": 154,
            "pid": 14,
            "name": "淮滨县",
            "level": 2
        }, {
            "id": 155,
            "pid": 14,
            "name": "息县",
            "level": 2
        }]
    }, {
        "id": 15,
        "pid": 0,
        "name": "商丘",
        "level": 1,
        "data": [{
            "id": 137,
            "pid": 15,
            "name": "梁园区",
            "level": 2
        }, {
            "id": 138,
            "pid": 15,
            "name": "睢阳区",
            "level": 2
        }, {
            "id": 139,
            "pid": 15,
            "name": "民权县",
            "level": 2
        }, {
            "id": 140,
            "pid": 15,
            "name": "睢县",
            "level": 2
        }, {
            "id": 141,
            "pid": 15,
            "name": "宁陵县",
            "level": 2
        }, {
            "id": 142,
            "pid": 15,
            "name": "柘城县",
            "level": 2
        }, {
            "id": 143,
            "pid": 15,
            "name": "虞城县",
            "level": 2
        }, {
            "id": 144,
            "pid": 15,
            "name": "夏邑县",
            "level": 2
        }, {
            "id": 145,
            "pid": 15,
            "name": "永城市",
            "level": 2
        }]
    }, {
        "id": 16,
        "pid": 0,
        "name": "周口",
        "level": 1,
        "data": [{
            "id": 156,
            "pid": 16,
            "name": "川汇区",
            "level": 2
        }, {
            "id": 157,
            "pid": 16,
            "name": "扶沟县",
            "level": 2
        }, {
            "id": 158,
            "pid": 16,
            "name": "西华县",
            "level": 2
        }, {
            "id": 159,
            "pid": 16,
            "name": "商水县",
            "level": 2
        }, {
            "id": 160,
            "pid": 16,
            "name": "沈丘县",
            "level": 2
        }, {
            "id": 161,
            "pid": 16,
            "name": "郸城县",
            "level": 2
        }, {
            "id": 162,
            "pid": 16,
            "name": "淮阳区",
            "level": 2
        }, {
            "id": 163,
            "pid": 16,
            "name": "太康县",
            "level": 2
        }, {
            "id": 164,
            "pid": 16,
            "name": "鹿邑县",
            "level": 2
        }, {
            "id": 165,
            "pid": 16,
            "name": "项城市",
            "level": 2
        }]
    }, {
        "id": 17,
        "pid": 0,
        "name": "驻马店",
        "level": 1,
        "data": [{
            "id": 166,
            "pid": 17,
            "name": "驿城区",
            "level": 2
        }, {
            "id": 167,
            "pid": 17,
            "name": "西平县",
            "level": 2
        }, {
            "id": 168,
            "pid": 17,
            "name": "上蔡县",
            "level": 2
        }, {
            "id": 169,
            "pid": 17,
            "name": "平舆县",
            "level": 2
        }, {
            "id": 170,
            "pid": 17,
            "name": "正阳县",
            "level": 2
        }, {
            "id": 171,
            "pid": 17,
            "name": "确山县",
            "level": 2
        }, {
            "id": 172,
            "pid": 17,
            "name": "泌阳县",
            "level": 2
        }, {
            "id": 173,
            "pid": 17,
            "name": "汝南县",
            "level": 2
        }, {
            "id": 174,
            "pid": 17,
            "name": "遂平县",
            "level": 2
        }, {
            "id": 175,
            "pid": 17,
            "name": "新蔡县",
            "level": 2
        }]
    }, {
        "id": 18,
        "pid": 0,
        "name": "济源",
        "level": 1,
        "data": []
    }]
    return b
}