import axios from 'axios';
import router from '../router';
import {Message} from 'element-ui'
// Vue.use(Message)
let showMsg = true;
// 添加一个请求拦截器, 只要登录了才能发请求
axios.defaults.baseURL = 'https://backend.cvhenan.com/';


axios.interceptors.request.use(function (config) {
  let token = localStorage.getItem('token')
  if (token) {
    //请求带上token
    config.headers['token'] = token
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

//响应拦截器
// axios.interceptors.response.use(function (response) {
//   console.log(response.data)
//     if (response.data) {
//       console.log(response.data)

//     }
//     return response;

//   },
//   function (error) {

//     return Promise.reject(error);

//   });
axios.interceptors.response.use(response => {
  // if (response) {
  //   console.log(response.data.data)
  // }
  return response;
  
}, err => {
  let notice
  if (err && err.response) {
    
    // var _this = this
    switch (err.response.status) {
      case 400:
        notice = err.response.data.msg
        Message.error( {message: notice ,center:true} )
        break;
        case 401:
        notice = err.response.data.msg
        if(showMsg){
          showMsg = false
          Message.error( {message: notice ,center:true} )
          setTimeout(()=>{
            showMsg = true
            router.push({ path: '/login' });
          },1000)
        }
        // router.push({ path: '/login' });
        localStorage.clear()
        // 跳转登录页面
        break;
        case 422:
          notice = err.response.data.msg
        Message.error( {message: notice ,center:true} )
          break;
      default:
        // return Promise.resolve(err);
    }
  }else{
    notice = '连接服务器失败!'
  }
  // Message.err( {message: notice } )


  return Promise.resolve(err);
})
// axios.interceptors.response.use(function (response) {
//   const status = response.status;
//   if (status == 400){
//     console.log('213')
//     console.log(response)
//   } else {

//   }
// });




//获取首页信息
export const shouindex = () => {
  return axios.get('/api/index').then(res => res.data)
}

//注册借口
export const register = (obj) => {
  return axios.post('/api/user/register',obj).then(res => res.data)
}

//发送验证码
export const sms = (obj) => {
  return axios.post('/api/sms/send', obj).then(res => res.data)
}

//用户注册
export const login = (obj) => {
  return axios.post('/api/user/login', obj).then(res => res.data)
}

//验证码登录
export const codelogin = (obj) => {
  return axios.post('/api/user/mobilelogin', obj).then(res => res.data)
}

//作品分类
export const cateFen = (obj) => {
  return axios.post('/api/common/categorys', obj).then(res => res.data)
}

//所在区域
export const address = (obj) => {
  return axios.post('/api/common/areas', obj).then(res => res.data)
}

//获取oss 参数
export const ossconfig = (obj) => {
  return axios.post('/api/common/getOssParam', obj).then(res => res.data)
}

//提交文件
export const publicphotos = (obj) => {
  return axios.post('/api/work/atlas/save', obj).then(res => res.data)
}

//图集列表
export const photoslist = (obj) => {
  return axios.post('/api/work/atlas', obj).then(res => res.data)
}

//文章列表
export const worklist = (obj) => {
  return axios.post('/api/work/article', obj).then(res => res.data)
}

//点赞
export const photolike = (obj) => {
  return axios.post('/api/work/atlas/like', obj).then(res => res.data)
}

//文章详情
export const articledetail = (obj) => {
  return axios.post('/api/work/article/detail', obj).then(res => res.data)
}

//guanzhu zuozhe 
export const  followCreator= (obj) => {
  return axios.post('/api/creator/followCreator', obj).then(res => res.data)
}

//取消关注
export const  CanclefollowCreator= (obj) => {
  return axios.post('/api/creator/cancelFollowCreator', obj).then(res => res.data)
}

//文章点赞
export const  articleLike= (obj) => {
  return axios.post('/api/work/article/like', obj).then(res => res.data)
}

//图片详情
export const  photodetail= (obj) => {
  return axios.post('/api/work/atlas/detail', obj).then(res => res.data)
}


//发表文章
export const  publicarticle= (obj) => {
  return axios.post('/api/work/article/save', obj).then(res => res.data)
}


//发表音频
export const  publicmedia= (obj) => {
  return axios.post('/api/work/audio/save', obj).then(res => res.data)
}

//发表shipin
export const  publicvideo= (obj) => {
  return axios.post('/api/work/video/save', obj).then(res => res.data)
}

//视频授权方式
export const  videotype= (obj) => {
  return axios.post('/api/common/authTypes', obj).then(res => res.data)
}


//视频授权方式
export const  videoList= (obj) => {
  return axios.post('/api/work/video', obj).then(res => res.data)
}

//视频详情
export const  videoDetail= (obj) => {
  return axios.post('/api/work/video/detail', obj).then(res => res.data)
}

//视频喜欢
export const  videoLike= (obj) => {
  return axios.post('/api/work/video/like', obj).then(res => res.data)
}

//音频详情
export const  mediadetail= (obj) => {
  return axios.post('/api/work/audio/detail', obj).then(res => res.data)
}

//音频列表
export const  medialist= (obj) => {
  return axios.post('/api/work/audio', obj).then(res => res.data)
}

//音频r热播列表
export const  mediaHot= (obj) => {
  return axios.post('/api/work/audio/hot', obj).then(res => res.data)
}

//音频r新品
export const  mediaNew= (obj) => {
  return axios.post('/api/work/audio/news', obj).then(res => res.data)
}


// 音频喜欢
export const  mediaLike= (obj) => {
  return axios.post('/api/work/audio/like', obj).then(res => res.data)
}

// 文章评论列表
export const  articleComm= (obj) => {
  return axios.post('/api/work/article/commentList', obj).then(res => res.data)
}

// 文章评论
export const  articleCommSub= (obj) => {
  return axios.post('/api/work/article/comment', obj).then(res => res.data)
}

// 文章评论点赞
export const  articleCommLike= (obj) => {
  return axios.post('/api/work/article/commentZan', obj).then(res => res.data)
}

// 图片评论列表
export const  photoleComm= (obj) => {
  return axios.post('/api/work/atlas/commentList', obj).then(res => res.data)
}

// 图片评论
export const  photoCommSub= (obj) => {
  return axios.post('/api/work/atlas/comment', obj).then(res => res.data)
}

// 图片评论点赞
export const  photoCommLike= (obj) => {
  return axios.post('/api/work/atlas/commentZan', obj).then(res => res.data)
}



// 视频评论列表
export const  videoleComm= (obj) => {
  return axios.post('/api/work/video/commentList', obj).then(res => res.data)
}

// 水品评论
export const  videoCommSub= (obj) => {
  return axios.post('/api/work/video/comment', obj).then(res => res.data)
}

// 视频评论点赞
export const  videoCommLike= (obj) => {
  return axios.post('/api/work/video/commentZan', obj).then(res => res.data)
}


// 音频评论列表
export const  audioleComm= (obj) => {
  return axios.post('/api/work/audio/commentList', obj).then(res => res.data)
}

// 音频评论
export const  audioCommSub= (obj) => {
  return axios.post('/api/work/audio/comment', obj).then(res => res.data)
}

// 音频评论点赞
export const  audioCommLike= (obj) => {
  return axios.post('/api/work/audio/commentZan', obj).then(res => res.data)
}


// 创作者列表
export const  creatorList= (obj) => {
  return axios.post('/api/creator', obj).then(res => res.data)
}


// 创作者文章列表
export const  creatorArticleList= (obj) => {
  return axios.post('/api/creator/article', obj).then(res => res.data)
}

// 创作者视频列表
export const  creatorvideoList= (obj) => {
  return axios.post('/api/creator/video', obj).then(res => res.data)
}

// 创作者音频列表
export const  creatoraudioList= (obj) => {
  return axios.post('/api/creator/audio', obj).then(res => res.data)
}

// 创作者图片
export const  creatoratlasList= (obj) => {
  return axios.post('/api/creator/atlas', obj).then(res => res.data)
}

// 创作者信息
export const  creatorinfo= (obj) => {
  return axios.post('/api/creator/creator', obj).then(res => res.data)
}



// 创作者信息
export const  userprofile= (obj) => {
  return axios.post('/api/user', obj).then(res => res.data)
}

// 重置米阿么
export const  resetpwd= (obj) => {
  return axios.post('/api/user/resetpwd', obj).then(res => res.data)
}

// 个人信息获取
export const  getMyInfo= (obj) => {
  return axios.post('/api/user/info', obj).then(res => res.data)
}

// 个人资料更改
export const  changeProfile= (obj) => {
  return axios.post('/api/user/profile', obj).then(res => res.data)
}


// 个人资料更改
export const  getJob= (obj) => {
  return axios.post('/api/user/getSelectOptions', obj).then(res => res.data)
}


// 更改手机号码
export const  changeMobile= (obj) => {
  return axios.post('/api/user/changemobile', obj).then(res => res.data)
}


// 实名状态查询
export const  authStatus= (obj) => {
  return axios.post('/api/user/authStatus', obj).then(res => res.data)
}

// 实名认账
export const  subAuth= (obj) => {
  return axios.post('/api/user/auth', obj).then(res => res.data)
}

// 我喜欢视频列表
export const  myVideo= (obj) => {
  return axios.post('/api/user/likeVideo', obj).then(res => res.data)
}


// 我喜欢音频列表
export const  myMedia= (obj) => {
  return axios.post('/api/user/likeAudio', obj).then(res => res.data)
}

// 我喜欢文章列表
export const  myArticle= (obj) => {
  return axios.post('/api/user/likeArticle', obj).then(res => res.data)
}

// 我喜欢图集列表
export const  myPhoto= (obj) => {
  return axios.post('/api/user/likeAtlas', obj).then(res => res.data)
}



// 视频列表
export const  meVideo= (obj) => {
  return axios.post('/api/user/video', obj).then(res => res.data)
}


// 音频列表
export const  meMedia= (obj) => {
  return axios.post('/api/user/audio', obj).then(res => res.data)
}

// 文章列表
export const  meArticle= (obj) => {
  return axios.post('/api/user/article', obj).then(res => res.data)
}

// 图集列表
export const  mePhoto= (obj) => {
  return axios.post('/api/user/atlas', obj).then(res => res.data)
}



// 更改背景图片
export const  changeBack= (obj) => {
  return axios.post('/api/user/changeBackgroundImg', obj).then(res => res.data)
}


// 退出
export const  logout= (obj) => {
  return axios.post('/api/user/logout', obj).then(res => res.data)
}


// 搜索
export const  search= (obj) => {
  return axios.post('/api/index/search', obj).then(res => res.data)
}


// 用户协议等内容
export const  agreeContent= (obj) => {
  return axios.post('/api/common/agreement', obj).then(res => res.data)
}


// 删除作品
export const  deleteWork= (obj) => {
  return axios.post('/api/user/deleteWork', obj).then(res => res.data)
}


// 编辑-获取视频信息
export const  editVideo= (obj) => {
  return axios.post('/api/user/editVideo', obj).then(res => res.data)
}

// 更新视频信息
export const  updateVideo= (obj) => {
  return axios.post('/api/user/updateVideo', obj).then(res => res.data)
}


// 编辑-获取视频信息
export const  editArticle= (obj) => {
  return axios.post('/api/user/editArticle', obj).then(res => res.data)
}

// 更新视频信息
export const  updateArticle= (obj) => {
  return axios.post('/api/user/updateArticle', obj).then(res => res.data)
}


// 编辑-获取视频信息
export const  editMedia= (obj) => {
  return axios.post('/api/user/editAudio', obj).then(res => res.data)
}

// 更新视频信息
export const  updateMedia= (obj) => {
  return axios.post('/api/user/updateAudio', obj).then(res => res.data)
}


// 编辑-获取视频信息
export const  editatlas= (obj) => {
  return axios.post('/api/user/editatlas', obj).then(res => res.data)
}

// 更新视频信息
export const  updateatlas= (obj) => {
  return axios.post('/api/user/updateatlas', obj).then(res => res.data)
}


// 高校联盟
export const  school= (obj) => {
  return axios.post('/api/union', obj).then(res => res.data)
}

// 创作者粉丝接口
export const  creatorFans= (obj) => {
  return axios.post('/api/creator/creatorFans', obj).then(res => res.data)
}

// 创作者粉关注
export const  creatorFollows= (obj) => {
  return axios.post('/api/creator/creatorFollows', obj).then(res => res.data)
}


// 我的粉丝接口
export const  myFans= (obj) => {
  return axios.post('/api/user/myFans', obj).then(res => res.data)
}

// 我的关注
export const  myFollows= (obj) => {
  return axios.post('/api/user/myFollows', obj).then(res => res.data)
}

// 我的评论
export const  myComments= (obj) => {
  return axios.post('/api/user/myComments', obj).then(res => res.data)
}

// 删除评论
export const  deleteComment= (obj) => {
  return axios.post('/api/user/deleteComment', obj).then(res => res.data)
}

// 文章举报
export const  articleReport= (obj) => {
  return axios.post('/api/work/article/report', obj).then(res => res.data)
}

// 视频举报
export const  videoReport= (obj) => {
  return axios.post('/api/work/video/report', obj).then(res => res.data)
}


// 音频举报
export const  audioReport= (obj) => {
  return axios.post('/api/work/audio/report', obj).then(res => res.data)
}

// 图集举报
export const  atlasReport= (obj) => {
  return axios.post('/api/work/atlas/report', obj).then(res => res.data)
}


// 图集举报
export const  commReport= (obj) => {
  return axios.post('/api/user/commentReport', obj).then(res => res.data)
}

// 二维码
export const  getAuthUrl= (obj) => {
  return axios.post('/third/api/getAuthUrl', obj).then(res => res.data)
}

// 查询二维码
export const  thirdLogin= (obj) => {
  return axios.post('/api/user/thirdLogin', obj).then(res => res.data)
}


// 解除绑定
export const  thirdUnbind= (obj) => {
  return axios.post('/api/user/thirdUnbind', obj).then(res => res.data)
}

// 所有列表
export const  mediaList = (obj) => {
  return axios.post('/api/media', obj).then(res => res.data)
}

// 发布图片label
export const  atlasTag = (obj) => {
  return axios.post('/api/work/atlas/recommendTags', obj).then(res => res.data)
}

// 发布视频label
export const  videoTag = (obj) => {
  return axios.post('/api/work/video/recommendTags', obj).then(res => res.data)
}


// 发布视频label
export const  articleTag = (obj) => {
  return axios.post('/api/work/article/recommendTags', obj).then(res => res.data)
}

// 发布视频label
export const  audioTag = (obj) => {
  return axios.post('/api/work/audio/recommendTags', obj).then(res => res.data)
}


// 发布视频label
export const  getUserBg = (obj) => {
  return axios.post('/api/user/backgroundImgs', obj).then(res => res.data)
}


// 参与活动
export const  joinActive = (obj) => {
  return axios.post('/api/activity/activity/enroll', obj).then(res => res.data)
}


// 作者列表
export const  activAuthors = (obj) => {
  return axios.post('/api/activity/activity/authors', obj).then(res => res.data)
}

// 作品列表
export const  activWorks = (obj) => {
  return axios.post('/api/activity/activity/works', obj).then(res => res.data)
}

// 评论列表
export const  activCommList= (obj) => {
  return axios.post('/api/activity/activity/commentList', obj).then(res => res.data)
}

// 评论内容
export const  activComm= (obj) => {
  return axios.post('/api/activity/activity/comment', obj).then(res => res.data)
}

// 点赞评论
export const  activCommZan= (obj) => {
  return axios.post('/api/activity/activity/commentZan', obj).then(res => res.data)
}
// 投票评论
export const  activeVote= (obj) => {
  return axios.post('/api/activity/activity/vote', obj).then(res => res.data)
}

// 获取配置
export const  getWeConfig= (obj) => {
  return axios.post('/api/common/wechatJsConfig', obj).then(res => res.data)
}


// 获取配置
export const  activeRule= (obj) => {
  return axios.post('/api/activity/activity/rule', obj).then(res => res.data)
}


// 当选
export const  activeExhibition= (obj) => {
  return axios.post('/api/activity/activity/exhibition', obj).then(res => res.data)
}

// 通知列表
export const  noticeList = (obj) => {
  return axios.post('/api/notice/index', obj).then(res => res.data)
}

// 通知详情
export const  noticeDetail = (obj) => {
  return axios.post('/api/notice/detail', obj).then(res => res.data)
}

// 通知未读数量
export const  noticeUnreadNum = (obj) => {
  return axios.post('/api/notice/unreadNum', obj).then(res => res.data)
}

// 通知全部标记为已读
export const  noticeAllRead = (obj) => {
  return axios.post('/api/notice/allRead', obj).then(res => res.data)
}


// 通知唯独
export const  noticeUnread= (obj) => {
  return axios.post('/api/notice/unread', obj).then(res => res.data)
}

// 活动参数
export const  cooperation= (obj) => {
  return axios.post('/api/cooperation', obj).then(res => res.data)
}

// 活动参数
export const  urge= (obj) => {
  return axios.post('/api/creator/urge', obj).then(res => res.data)
}



// 活动轮播图片
export const  activebanana= (obj) => {
  return axios.post('/api/activity/activity/carousels', obj).then(res => res.data)
}

// 活动轮播列表
export const  activelist= (obj) => {
  return axios.post('/api/activity/activity', obj).then(res => res.data)
}

// 活动内容
export const  activeDetail= (obj) => {
  return axios.post('/api/activity/activity/detail', obj).then(res => res.data)
}


