<template>
  <transition
    enter-active-class="animate__animated  animate__fadeInDown"
    leave-active-class="animate__animated animate__fadeOutUp"
  >
    <!-- 显示地区 -->
    <div v-cloak>
      <div class="main" v-show="up">
        <div class="center">
          <div class="centerbox-one">
            <div class="one-left">
              <div class="logobox">
                <div class="logo">
                  <img src="../assets/img/logo.png" alt="" />
                </div>
              </div>
              <el-menu
                :default-active="$route.path"
                class="el-menu-demo"
                mode="horizontal"
                background-color="#ffffff"
                active-text-color="#e35111"
                text-color="#333333"
                router
                @select="handSelect"
              >
                <el-menu-item
                  index="/"
                  :class="activeClass == '/' ? 'itemactive' : ''"
                  >首页</el-menu-item
                >
                <!-- <el-menu-item index="/videolist" class="bgNav  customNav"
                >
                发现
                <div class="customNavbox">

                </div>
                
                </el-menu-item
              > -->
                <!-- <el-submenu index="/videolist" class="test" >
                <template slot="title" >发现</template>
                <el-menu-item index="/photolist">赏河南</el-menu-item>
                <el-menu-item index="/articlelist">读河南</el-menu-item>
                <el-menu-item index="/videolist">看河南</el-menu-item>
                <el-menu-item index="/medialist">听河南</el-menu-item>
              </el-submenu> -->
                <el-menu-item
                  index="/itemlist"
                  class="bgNav"
                  :class="activeClass == '/itemlist' ? 'itemactive' : ''"
                  >品河南</el-menu-item
                >
                <el-menu-item
                  index="/creatorlist"
                  class="bgNav"
                  :class="activeClass == '/creatorlist' ? 'itemactive' : ''"
                  >创作人</el-menu-item
                >
                <el-submenu index="/school"
                  :class="[activeClass == '/school' ? 'itemactive subitemactive' : '',activeClass == '/adschool' ? 'itemactive subitemactive' : '']">
                  <template slot="title">社会团体</template>
                  <el-menu-item index="/school" >河南高校影视教育协会</el-menu-item>
                  <el-menu-item index="/adschool">中原广告研究院</el-menu-item>
                </el-submenu>
                <!-- <el-menu-item index="/school" class="bgNav" :class="activeClass == '/school' ? 'itemactive':''"
                >高校联盟</el-menu-item
              > -->
                <!-- <el-menu-item index="4" class="bgNav">活动</el-menu-item> -->
                <!-- <el-submenu index="5" class="bgNav">
              <template slot="title">更多</template>
              <el-menu-item index="5-1">选项1</el-menu-item>
              <el-menu-item index="5-2">选项2</el-menu-item>
              <el-menu-item index="5-3">选项3</el-menu-item>
            </el-submenu> -->
              </el-menu>
            </div>

            <div class="search">
              <div class="searchinput">
                <input type="text" class="searchbox" v-model="key" />
                <div class="searchimg cursorstyle" @click="search">
                  <img src="../assets/img/search.png" alt="" />
                </div>
              </div>
            </div>
            <!-- <div class="searchone">
              <i class="el-icon-search searchsize"></i>
            </div> -->
          </div>

          <div class="centerbox-two " v-if="user !== ''">
            <!-- 这个地方显示通知 -->
            <el-popover
              placement="bottom"
              width="400"
              trigger="click"
              ref="popover"
              @show="getNoticeList"
              @after-leave="noticeClose"
            >
              <div class="noticeitem">
                <div
                  class="listbox"
                  ref="noticel"
                  id="listbox"
                  v-show="noticeTotal !== 0"
                >
                  <div
                    class="item cursorstyle"
                    v-for="(item, nx) in noticelist"
                    :key="nx"
                    @click="toNoticeDetail(item.id)"
                  >
                    <p :class="item.is_read == 0 ? 'noread' : ''">
                      {{ item.title }}
                    </p>
                  </div>
                  <p
                    class="txt"
                    v-show="noticeCurrPage == noticeLastpage"
                    style="padding: 20px;
    text-align: center;
    color: #a9a9a9;"
                  >
                    没有更多内容了
                  </p>
                </div>

                <!-- 无数据提示 -->
                <div class=" noticeEmpty" v-show="noticeTotal == 0">
                  <div>
                    <div class="emptyone">
                      <img
                        src="../assets/img/empty.png"
                        alt=""
                        class="emptyimg"
                      />
                    </div>
                    <div class="emptytxt">
                      <p>暂时没有消息，过会再来看看吧</p>
                    </div>
                  </div>
                </div>
                <div class="bottom-box">
                  <p
                    class="cursorstyle bottom-c"
                    @click="setAllRead"
                    v-show="noticeTotal !== 0"
                  >
                    一键已读所有通知
                  </p>
                  <p class="cursorstyle bottom-c" @click="toNoticeL">
                    查看全部通知
                  </p>
                </div>
              </div>
              <!-- <el-button slot="reference">click 激活</el-button> -->
              <div class="noticebox cursorstyle" slot="reference">
                <el-badge :value="noticeInfi" class="item">
                  <i class="el-icon-bell notice"></i>
                </el-badge>
              </div>
            </el-popover>

            <el-dropdown @command="handleCommandone" style="display:inherit">
              <img :src="user.avatar" alt="" class="avatar cursorstyle" />
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, navmy) in myList"
                  :key="navmy"
                  :command="item"
                  >{{ item.name }}</el-dropdown-item
                >
                <el-dropdown-item
                  command="login"
                  style="color: red;text-align: center;"
                  >退出</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown @command="handleCommandtwo">
              <p class="upload cursorstyle">上传</p>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, nav) in downlist"
                  :key="nav"
                  :command="item.path"
                  >{{ item.name }}</el-dropdown-item
                >
                <!-- <el-dropdown-item
              command="login"
              style="color: red;text-align: center;"
              >退出</el-dropdown-item
            > -->
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="centerbox-two ml cursorstyle" v-else>
            <p class="upload" @click="toLogin">登录/注册</p>
          </div>
        </div>
        <!-- <div class="right"></div> -->
      </div>
      <div class="addll" :class="activeClass == '/' ? 'allshow' : ''">
        <div
          class="item cursorstyle"
          v-for="(item, ax) in addressList"
          :key="ax"
          @click="locatC(item)"
        >
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  logout,
  authStatus,
  noticeList,
  noticeAllRead,
  noticeUnreadNum,
  noticeUnread,
} from "@/api";
import { mapState } from "vuex";
import { NewArealist } from "../assets/js/area";
export default {
  components: {},
  data() {
    return {
      activeIndex: "/",
      user: "",
      bg: {
        backgroundColor: "#f7f7f7",
      },
      noticeShow: false,
      noticelist: [],
      noticeCurrPage: 1,
      noticeLastpage: 0,
      noticeTotal: null,
      noticeInfi: "",
      downlist: [
        { name: "发表图集", path: "upload" },
        { name: "发表文章", path: "publicarticle" },
        { name: "发表音频", path: "publicmedia" },
        { name: "发表视频", path: "publicvideo" },
      ],
      myList: [
        { name: "个人中心", path: "myworks", type: "" },
        { name: "我的喜欢", path: "userworks", type: "0" },
        { name: "我的评论", path: "userworks", type: "3" },
        { name: "我的粉丝", path: "userworks", type: "2" },
        { name: "实名认证", path: "personalcenter", type: "2" },
        { name: "账号设置", path: "personalcenter", type: "0" },
      ],
      oldScrollTop: 0,
      key: "",
      up: true,
      count: 0,
      fnScroll: () => {},

      addressList: [],
      activeClass: "/",
 
    };
  },
  watch: {
    userInfo: function(val) {
      console.log(val);
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    handSelect: function(index) {
      // this.activeIndex = index
      this.activeClass = index;
      
    },
    locatC: function(e) {
      //  this.getFourList();
      let fourF = this.$router.resolve({
        path: "/itemlist",
        query: {
          id: e.index,
        },
      });
      window.open(fourF.href, "_blank");
    },
    //首页登陆
    toLogin: function() {
      this.$router.push("/login");
    },
    toUpload: function() {
      this.$router.push("/upload");
    },
    search: function() {
      //  this.$router.push("/search",{
      //     query: {
      //     key: this.key,
      //   },
      //  });
      this.$router.push({
        path: "/search",
        query: {
          key: this.key,
        },
      });
    },

    handleCommandone(e) {
      // console.log(this.$store.state.userInfo)

      if (e == "login") {
        logout().then((res) => {
          localStorage.clear();
          this.$router.push("/login");
        });
      } else {
        this.$router.push({
          path: `/${e.path}`,
          query: {
            type: e.type,
          },
        });
      }
      // this.$message('click on item ' + command);
    },
    handleCommandtwo(e) {
      authStatus().then((res) => {
        if (res.data.auth_status == 2) {
          this.$router.push(`/${e}`);
        } else {
          this.$router.push({
            path: "/personalcenter",
            query: {
              type: "2",
            },
          });
        }
      });
      // this.$message('click on item ' + command);
    },
    toCenter: function() {
      this.$router.push({
        path: "/personalcenter",
        query: {
          type: "2",
        },
      });
    },
    fixedActiveBtn() {
      let flag = true;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var scroll = scrollTop - this.oldScrollTop;

      this.oldScrollTop = scrollTop;
      if (scroll < 0) {
        // console.log('up')
        //添加你想要的事件
        this.up = true;
      } else {
        //添加你想要的事件
        // console.log('down')
        this.up = false;
      }
      //   if (scrollTop > 59) {
      //     this.up = true
      //   }else if(scrollTop == 0){

      //   }else{
      //     this.up = false
      //   }
      // if (scrollTop < 107) {
      //   this.up = true
      // }
      // if (scrollTop >= 107) {
      //     if (this.oldScrollTop < scrollTop ) {
      //        console.log('shang ')
      //     }else{
      //        console.log('xia ')
      //     }
      //   this.oldScrollTop = scrollTop

      // }
    },
    getNoticeList: function() {
      // box.addEventListener('scroll',this.handSceoll,true);
      noticeUnread({
        page: this.noticeCurrPage,
      }).then((res) => {
        this.noticelist = [...this.noticelist, ...res.data.data];
        this.noticeCurrPage = res.data.current_page;
        this.noticeLastpage = res.data.last_page;
        if (!this.noticeTotal) {
          this.handSceoll();
        }
        this.noticeTotal = res.data.total;
      });
    },
    toNoticeDetail: function(e) {
      let fourF = this.$router.resolve({
        path: "/noticeinfo",
        query: {
          id: e,
        },
      });
      window.open(fourF.href, "_blank");
    },
    handSceoll: function() {
      var el = document.querySelector("#listbox");
      var offsetHeight = el.offsetHeight;
      el.onscroll = () => {
        var scrollTop = el.scrollTop;
        var scrollHeight = el.scrollHeight;
        if (offsetHeight + scrollTop - scrollHeight >= 0) {
          this.noticeCurrPage++;
          if (this.noticeCurrPage !== this.noticeLastpage) {
            this.getNoticeList();
          }
        }
      };
    },
    setAllRead: function() {
      noticeAllRead().then((res) => {
        this.$refs.popover.doClose();
        if (res.code == 200) {
          this.noticeClose();
          this.$message({
            message: res.msg,
            type: "success",
          });
        }
        this.noticeNum();
      });
    },
    noticeNum: function() {
      noticeUnreadNum().then((res) => {
        if (res.data > 0) {
          this.noticeInfi = res.data;
        } else {
          this.noticeInfi = "";
        }
      });
    },
    noticeClose: function() {
      this.noticelist = [];
      this.noticeTotal = null;
    },
    toNoticeL: function() {
      let fourF = this.$router.resolve({
        path: "/notice",
      });
      window.open(fourF.href, "_blank");
    },

    updata:function(){
     this.noticeNum()
    }
  },
  created() {
    if (Object.values(this.$store.state.userInfo.userinfo).length == 0) {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
        this.$store.dispatch("userInfo/saveUserInfo", JSON.parse(user));
      }
    } else {
      this.user = this.$store.state.userInfo.userinfo;
    }

    // 更改地区
    let address = NewArealist();
    var newArr = [];
    address.map((item) => {
      newArr.push({
        index: item.id,
        name: item.name,
      });
    });
    newArr.unshift({ index: "", name: "河南" });
    this.addressList = newArr;


  },
  mounted() {
    window.updata = this.updata
    //核心开始------------------------------------
    let oldTop = 0; //旧数据，初始为0
    // 将自定义方法绑定到窗口滚动条事件
    // window.onscroll = () => {
    //   let top = document.scrollingElement.scrollTop; //触发滚动条，记录数值
    //   // console.log(top)
    //   if (oldTop > top) {
    //      this.up = true;

    //   } else {

    //      this.up = false;
    //     // console.log("↓");
    //   }
    //   oldTop = top;//更新旧的位置
    // }
    // window.addEventListener("scroll", this.throttle(this.fixedActiveBtn));
    // 页面手动刷新指定路由
    //获取地址栏中的路由，设置elementui中的导航栏选中状态
    let defaultMenu = window.location.hash.substr(
      window.location.hash.indexOf("/")
    );
    this.activeIndex = defaultMenu;
    this.activeClass = this.$route.path;

    let token = localStorage.getItem("token");
    if (token) {
      //请求带上token
      this.noticeNum();
    }
  },
};
</script>
<style lang="scss" scoped>
[v-cloak] {
  display: none !important;
}
.allshow {
  display: flex !important;
}
.all {
  transition: all 0.5s ease;
  height: 60px;
}



.main {
  height: 60px;
  background: #ffffff;
  line-height: 60px;
  display: flex;
  transition: all 0.5s ease;
  //  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  // margin-bottom: 60px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.nofix {
  display: none;
}
.center {
  display: flex;
  // width: 100%;
  // padding: 0 60px;
  width: 1200px;
  margin: 0 auto;
  // margin: 0 auto;
  //   justify-content: space-between;
}

.logobox {
  display: flex;
  width: 112px;
  align-items: center;
  margin-right: 10px;
}
.logo {
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
  .logotxt {
    font-weight: 600;
    color: #000;
  }
}
.el-menu.el-menu--horizontal {
  border-bottom: 0px;
}
.searchimg {
  img {
    width: 20px;
    height: 20px;
  }
}
.search {
  display: flex;
  align-items: center;
}
.searchinput {
  width: 250px;
  height: 30px;
  border: 1px solid #999999;
  border-radius: 19px;
  display: flex;
  align-items: center;
}
.searchbox {
  background: none;
  outline: none;
  border: none;
  width: 210px;
  padding-left: 10px;
}
.searchimg {
  height: 38px;
  display: flex;
  align-items: center;
}
.centerbox-one {
  display: flex;
  flex: 1;
  justify-content: space-around;
  .one-left {
    display: flex;
    flex: 1;
    min-width: 500px;
    overflow: hidden;
  }
}
.centerbox-two {
  // .notice {
  //   width: 20px;
  //   height: 22px;
  //   margin-right: 30px;
  // }
  
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 30px;
  }
  .upload {
    width: 90px;
    height: 32px;
    line-height: 32px;
    background: #e35111;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
  }
}
.centerbox-two {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  // margin-left: 300px;
}

// @media (max-width: 1290px) {
//   .centerbox-two {
//     margin-left: 0px;
//   }
//   .centerbox-one {
//     margin-right: 10px;
//   }
//   .center {
//     width: 1300px;
//   }
// }

.customNav {
  position: relative;

  .customNavbox {
    position: absolute;
    width: 200px;
    height: 0;
    background-color: orange;
    transition: all 0.5s;
    left: -20%;
    z-index: 99;
  }
  &:hover .customNavbox {
    height: 200px;
  }
}

// .el-menu--horizontal > .el-menu-item.is-active {
//   border-bottom: none;
// }

.addll {
  // display: flex;d
  display: none;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  .item {
    text-align: center;
    flex: 1;
    padding: 10px;
    &:hover {
      color: #e35111;
    }
  }
}

.itemactive {
  // border-top: 2px solid #e35111 !important;
  border-color: #e35111 !important;
}

.noticebox {
  // margin-right: 30px;
  // display: flex;
  // align-items: center;
  margin-right: 35px;
  .item {
    display: flex;
    .notice {
      font-size: 25px;
    }
  }
}
.noticeitem {
  height: 400px;
  position: relative;
  .bottom-box {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    border-top: 1px solid #f1f1f1;
    .bottom-c:hover {
      color: #e35111;
    }
  }
  .listbox {
    height: 92%;
    overflow: auto;
  }
  .item {
    padding: 20px 0;
    border-bottom: 1px solid #f1f1f1;
  }
  .noread {
    font-weight: bold;
    color: #000;
  }
}

.noticeEmpty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .emptyone {
    display: flex;
    justify-content: center;
    .emptyimg {
      width: 150px;
      max-width: 100%;
    }
  }
  .emptytxt {
    text-align: center;
  }
}

.searchone{
  display: flex;
  align-items: center;
  .searchsize{
    font-size: 25px;
    color: #a9a9a9;
  }
}
</style>
<style>
/* .el-menu--horizontal > .el-submenu .el-submenu__title {
  background-color: #f7f7f7 !important;
} */
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: #f7f7f7;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus {
  background-color: #f7f7f7;
}
.el-menu-demo .el-menu-item {
  border-bottom-color: #ffffff !important;
  border-top: 4px solid #ffffff;
}
.el-menu--horizontal>.el-submenu{
  border-bottom-color: #ffffff !important;
  border-top: 4px solid #ffffff;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
  border-bottom: 0;
  background-color: transparent !important;
}
.el-menu--horizontal>.el-submenu .el-submenu__title{
     border-bottom: 0;
}
</style>
