import {
    Notification
} from 'element-ui';
export default {
    ws: {},
    delay: 1500,
    user: null,
    reconnect: 0,
    j: null,
    setWs: function (newWs) {
        this.ws = newWs
    },
    //设置延迟方法
    setDelay: function (newDelay) {
        this.delay = newDelay
    },
    //发送websocket信息方法
    sendMsg: function (message) {
        this.ws.send(JSON.stringify(message))
    },
    keepLive: function () {
        let that = this;
        setTimeout(() => {
            //判断当前webscokt状态
            if (that.ws.readyState == 1) {
                console.log('发送keepalve')
                //调用发送方法
                that.sendMsg({
                    "type": "heart",
                    "data": ''
                })
                that.keepLive()
            }
        }, 50000);
    },
    sockinit: function () {
        var self = this
        self.user = JSON.parse(localStorage.getItem('user'))
        if (!self.user) {
            return
        }
        self.ws = new WebSocket('wss://backend.cvhenan.com:6001')
        //   socket打开
        self.ws.onopen = function (e) {
            console.log('socket打开')
            if (self.ws.readyState == 1) {
                self.sendMsg({
                    "type": "register",
                    "data": {
                        "auth": `user_${self.user.id}`
                    }
                })
                self.keepLive()
                if (self.j) {
                    clearInterval(self.j)
                    self.j = null
                    self.reconnect = 0
                }

            }

        }

        //   socket错误
        self.ws.onerror = function () {
            console.log('socket错误')

            // setTimeout(() => {
            //     self.keepLive()
            // }, self.delay);
            self.sockReconnect()
        }
        // socket关闭
        self.ws.onclose = function () {
            console.log('socket关闭')
            self.sockReconnect()
        }
        // socket监听消息
        self.ws.onmessage = function (e) {

            var message = JSON.parse(e.data)
            switch (message.type) {
                case 'message':
                    self.updataNum()
                    Notification({
                        title: '新消息',
                        message: message.msg,
                        iconClass: 'el-icon-chat-line-square',
                        customClass: "custIcon",
                    })
                    break;
                case 'notice':
                    Notification({
                        title: '通知',
                        message: message.msg,
                        iconClass: 'el-icon-chat-line-square',
                        customClass: "custIcon",
                    })
                    break;

                default:
                    break;
            }
            // Notification.success({title:'提示',message:message.msg})
            // console.log('socket消息')
        }

    },
    sockClose: function () {
        var that = this
        if (that.ws.readyState == 1) {
            that.ws.close()
        }
    },
    sockReconnect: function () {
        var self = this
        self.j = setInterval(() => {
            self.sockinit()
            // self.reconnect++
        }, 20000);
    },

    updataNum: function () {
        updata()
    }

}