<template>
  <div class="" style="position: relative;" v-cloak>
    <div
      class="swiper swipermask"
      :style="{
        backgroundImage: 'url(' + swiperBack + ')',
        backgroundSize: 'cover',
      }"
    >
      <div class="swiper-box">
        <el-carousel height="400px" @change="swiperchange">
          <el-carousel-item
            v-for="(item, x) in carouselSwiper"
            :key="x"
            class="photoZoom cursorstyle"
          >
            <img
              :src="item.url"
              alt=""
              class="swiperimage"
              @click="swiperTo(item)"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- hor -->
    <div class="henan" style="display:none">
      <!--看菜单 -->
      <div class="newCenter">
        <!-- <div class="tab-box">
          <div class="tab-l">
            <img src="../assets/img/henan/left.png" alt="" />
          </div>
          <div class="tab-c">
            <div
              class="item "
           
              @click="fourC('video')"
              :class="fourCActive == 'video' ? 'fourNoActive' : 'fourActive'"
            >
              <p>
                <img src="../assets/img/henantab/tab3.png" alt="" srcset="" class="photo" v-show="fourCActive !== 'video'">
                 <img src="../assets/img/henantab/tab4.png" alt="" srcset="" class="photo" v-show="fourCActive == 'video'">
               看河南
                </p>
            </div>
             <div
              class="item "
           
              @click="fourC('article')"
              :class="fourCActive == 'article' ? 'fourNoActive' : 'fourActive'"
            >
              <p>
                 <img src="../assets/img/henantab/tab1.png" alt="" srcset="" class="photo" v-show="fourCActive !== 'article'">
                 <img src="../assets/img/henantab/tab2.png" alt="" srcset="" class="photo" v-show="fourCActive == 'article'">
               读河南
                </p>
            </div>
              <div
              class="item "
           
              @click="fourC('atlas')"
              :class="fourCActive == 'atlas' ? 'fourNoActive' : 'fourActive'"
            >
              <p>
                    <img src="../assets/img/henantab/tab5.png" alt="" srcset="" class="photo" v-show="fourCActive !== 'atlas'">
                 <img src="../assets/img/henantab/tab6.png" alt="" srcset="" class="photo" v-show="fourCActive == 'atlas'">
               赏河南
                </p>
            </div>
                 <div
              class="item "
           
              @click="fourC('audio')"
              :class="fourCActive == 'audio' ? 'fourNoActive' : 'fourActive'"
            >
              <p>
                   <img src="../assets/img/henantab/tab7.png" alt="" srcset="" class="photo" v-show="fourCActive !== 'audio'">
                 <img src="../assets/img/henantab/tab8.png" alt="" srcset="" class="photo" v-show="fourCActive == 'audio'">
               听河南
                </p>
            </div>
          </div>
          <div class="tab-l">
            <img src="../assets/img/henan/right.png" alt="" />
          </div>
        </div> -->
        <!-- <div class="newCente-one">
          <div class="item">
            <div
              class="item-box "
              v-for="(item, ninedx) in locationList"
              :key="ninedx"
              :style="`left:${ninedx * 64}px`"
              @click="locatC(item)"
            >
              <div
                class="list cursorstyle "
                :style="`animationDelay:${ninedx * 10}ms`"
                :class="anmitionFlag ? 'anmitionList':''"
              >
                <img
                  :src="require(`../assets/img/henan/${ninedx + 1}.png`)"
                  alt=""
                  class="photo "
                />
              </div>
              <div class="txt cursorstyle">
                <p>{{ item.name }}</p>
              </div>
          
              <div
                class="op cursorstyle"
              ></div>
            </div>
          </div>
        </div> -->

        <div class="listbox">
          <!-- 视频列表 -->
          <!-- <div v-show="fourCActive == 'video'">
            <div class="card-box">
              <el-row :gutter="20">
                <el-col
                  class="cursorstyle boxShadow  h300"
                  :span="6"
                  v-for="(item, fvindex) in fourVideo"
                  :key="fvindex"
                  ><div
                    class="grid-content"
                    style=" margin-bottom: 30px;"
                    @click="toNewVideo(item.id)"
                  >
                    <div class="card-box-one photoZoom">
                      <img v-lazy="item.cover" alt="" style="height:100%" />
                      <span class="transtime">{{
                        item.play_time | transTime
                      }}</span>
                    </div>
                    <div class="card-box-two">
                      <p>{{ item.title }}</p>
                    </div>
                    <div class="card-box-three">
                      <span>{{ item.area }}</span>
                      <span class="line">I</span>
                      <span class="line">{{ item.label_text }}</span>
                    </div>
                    <div class="card-box-four">
                      <div class="card-box-for-img">
                        <img v-lazy="item.user_info.avatar" alt="" />
                      </div>
                      <div class="card-box-for-author">
                        <span>{{ item.user_info.username }}</span>
                      </div>
                      <div class="card-box-for-job">
                        <span>{{ item.user_info.identity_text }}</span>
                      </div>
                    </div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div> -->

          <!-- 文章列表 -->
          <!-- <div v-show="fourCActive == 'article'">
            <div class="card-box">
              <el-row :gutter="20">
                <el-col
                  class="cursorstyle"
                  :span="6"
                  v-for="(item, faindex) in fourArticle"
                  :key="faindex"
                  ><div
                    class="grid-content"
                    @click="toNewArticleDetail(item.id)"
                  >
                    <div class="card-box-one photoZoom">
                      <img
                        v-lazy="item.cover"
                        alt=""
                        style="width: 100%;height: 100%;"
                      />
                    </div>
                    <div class="card-box-two" style="paddingBottom:20px">
                      <p>{{ item.title }}</p>
                    </div>

                  </div></el-col
                >
              </el-row>
            </div>
          </div> -->

          <!-- medio -->
          <!-- <div v-show="fourCActive == 'audio'" class="audiobox">
            <div class="item-box">
              <div
                class="item cursorstyle"
                v-for="(item, faindex) in fourAudio"
                :key="faindex"
                @click="toAudioDetail(item.id)"
              >
                <div class="item-one">
                  <img v-lazy="item.cover" alt="" />
                </div>
                <div class="item-two">
                  <p>{{ item.title }}</p>
                </div>
                <div class="item-three">
                  <img :src="item.user_info.avatar" alt="" />
                  <span>{{ item.user_info.username }}</span>
                </div>
              </div>
            </div>
          </div> -->

          <!-- tupian -->
          <!-- <div v-show="fourCActive == 'atlas'" class="fourPhoto">
            <div class="photo-box">
              <div class="card-box">
                <el-row :gutter="20">
                  <el-col
                    :span="6"
                    v-for="(item, fpindex) in fourAtlas"
                    :key="fpindex"
                    ><div
                      class="grid-content cursorstyle"
                      style=" margin-bottom: 30px;"
                      @click="tophoto(item.id)"
                    >
                      <div class="card-box-one ">
                        <img v-lazy="item.cover" alt="" />
                      </div>
                      <div class="card-box-two">
                        <p>{{ item.title }}</p>
                      </div>

                      <div
                        class="card-box-play"
                        style="display:flex;justify-content:space-between;padding-bottom:10px"
                      >
                        <div style="display:flex; align-items: center;">
                          <img
                            src="../assets/img/show.png"
                            alt=""
                            class="play"
                          />
                          <span style="margin-left:10px">{{
                            item.total_scan
                          }}</span>
                        </div>

                        <div style="padding-right:10px">
                          <span>{{ item.created_at | timeNew }}</span>
                        </div>
                      </div>
             
                    </div></el-col
                  >
                </el-row>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="mainbox">
      <div class="left"></div>
      <div class="center">
        <div class="centerone">
          <div class="onetxt">
            <div class="txt-l">
              最新发布 &nbsp;
              <span
                style="font-weight: normal;font-size: 20px;  color: #808080;"
                >RECENTLY ADDED</span
              >
            </div>
            <div class="txt-r cursorstyle" @click="tiitemList">
              更多
            </div>
          </div>
          <div class="card-box">
            <el-row :gutter="20">
              <el-col
                class="cursorstyle"
                :span="6"
                v-for="(item, neindex) in newArticle"
                :key="neindex"
                style="margin-bottom: 30px;"
                ><div class="grid-content">
                  <div
                    class="card-box-one "
                    :class="item.type == 3 ? 'imgflex' : ''"
                  >
                    <div
                      v-lazy:background-image="
                        item.cover + '?x-oss-process=image/resize,w_256'
                      "
                      class="bg photoZoomBg"
                      @click="toCatePage(item)"
                    ></div>
                    <div
                      class="cat"
                      :class="item.type == 1 || item.type == 2 ? 'r60' : 'r10'"
                    >
                      <img
                        src="../assets/img/henantab/tab4.png"
                        alt=""
                        srcset=""
                        v-if="item.type == 2"
                      />
                      <img
                        src="../assets/img/henantab/tab2.png"
                        alt=""
                        srcset=""
                        v-if="item.type == 4"
                      />
                      <img
                        src="../assets/img/henantab/tab6.png"
                        alt=""
                        srcset=""
                        v-if="item.type == 3"
                      />
                      <img
                        src="../assets/img/henantab/tab8.png"
                        alt=""
                        srcset=""
                        v-if="item.type == 1"
                      />
                    </div>
                    <span
                      class="transtime"
                      v-if="item.type == 1 || item.type == 2"
                      >{{ item.play_time | transTime }}</span
                    >
                  </div>
                  <div class="card-box-two">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="card-box-three">
                    <span>{{ item.area || "河南" }}</span>
                    <span class="line">I</span>
                    <span class="line">{{ item.label_text }}</span>
                  </div>
                  <div class="card-box-four">
                    <div class="card-box-for-img">
                      <img v-lazy="item.user_info.avatar" alt="" />
                    </div>
                    <div class="card-box-for-author">
                      <span>{{ item.user_info.username }}</span>
                    </div>
                    <div class="card-box-for-job">
                      <span>{{ item.user_info.identity_text }}</span>
                    </div>
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
        </div>
        <div class="centerone">
          <div class="onetxt">
            <div class="txt-l">
              编辑精选 &nbsp;
              <span
                style="font-weight: normal;font-size: 20px;  color: #808080;"
                >SATFF PICKS</span
              >
            </div>
            <div class="txt-r cursorstyle" @click="moreContent('2')">
              更多
            </div>
          </div>
          <div class="card-box">
            <el-row :gutter="20">
              <el-col
                class="cursorstyle boxShadow  h300"
                :span="6"
                v-for="(item, saindex) in selectList"
                :key="saindex"
                ><div
                  class="grid-content"
                  style=" margin-bottom: 30px;"
                  @click="tVideooDetail(item.id)"
                >
                  <div class="card-box-one photoZoom">
                    <img v-lazy="item.cover+'?x-oss-process=image/resize,w_256'" alt="" />
                    <span class="transtime">{{
                      item.play_time | transTime
                    }}</span>
                  </div>
                  <div class="card-box-two">
                    <p>{{ item.title }}</p>
                  </div>
                  <div class="card-box-three">
                    <span>{{ item.area || "河南" }}</span>
                    <span class="line">I</span>
                    <span class="line">{{ item.label_text }}</span>
                  </div>
                  <div class="card-box-four">
                    <div class="card-box-for-img">
                      <img v-lazy="item.user_info.avatar" alt="" />
                    </div>
                    <div class="card-box-for-author">
                      <span>{{ item.user_info.username }}</span>
                    </div>
                    <div class="card-box-for-job">
                      <span>{{ item.user_info.identity_text }}</span>
                    </div>
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
        </div>

        <div class="centerone">
          <div class="onetxt">
            <div class="txt-l">
              文章 &nbsp;
              <span
                style="font-weight: normal;font-size: 20px;  color: #808080;"
                >ARTICLE</span
              >
            </div>
            <div class="txt-r cursorstyle" @click="moreContent('4')">
              更多
            </div>
          </div>
          <div class="card-box">
            <el-row :gutter="20">
              <el-col
                class="cursorstyle"
                :span="6"
                v-for="(item, arindex) in articleList"
                :key="arindex"
              >
                <div class="grid-content" @click="toArticleDetail(item.id)">
                  <div class="card-box-one hovereffect">
                    <div class="articleShow">
                      <p>{{ item.content_abstract }}...</p>
                    </div>
                    <img
                      v-lazy="item.cover+'?x-oss-process=image/resize,w_256'"
                      alt=""
                      style="width: 100%;height: 100%;"
                    />
                  </div>
                  <div class="card-box-two" style="paddingBottom:20px">
                    <p>{{ item.title }}</p>
                  </div>
                  <!-- <div class="card-box-five">
                    <span>{{ item.description }}</span>
                  </div> -->
                  <!-- <div class="card-box-four">
                    <div class="card-box-for-img">
                      <img v-lazy="item.user_info.avatar" alt="" />
                    </div>
                    <div class="card-box-for-author">
                      <span>{{ item.area }}</span>
                    </div>
                    <div class="card-box-for-job">
                      <span>编辑</span>
                    </div>
                  </div> -->
                </div></el-col
              >
            </el-row>
          </div>
        </div>
        <div class="centerone">
          <div class="onetxt">
            <div class="txt-l">
              推荐创作人 &nbsp;
              <span
                style="font-weight: normal;font-size: 20px;  color: #808080;"
                >CREATORS</span
              >
            </div>
            <div class="txt-r cursorstyle" @click="toCreatorList">
              更多
            </div>
          </div>
          <div class="card-box-create">
            <el-row :gutter="20">
              <el-col
                :span="6"
                v-for="(item, crindex) in creator"
                :key="crindex"
                ><div class="grid-content">
                  <div class="creat-one cursorstyle" @click="toMe(item.id)">
                    <img v-lazy="item.background_img" alt="" />
                  </div>
                  <div class="create-two cursorstyle" @click="toMe(item.id)">
                    <div class="create-two-avatar">
                      <img v-lazy="item.avatar" alt="" />
                    </div>
                    <div class="create-three">
                      <p class="three-one cursorstyle" @click="toMe(item.id)">
                        {{ item.username }}
                      </p>
                      <p class="three-two">
                        <span>
                          <span @click.stop="toFans(item, '0')">
                            粉丝 &nbsp;</span
                          >
                          <span
                            style="color:#333333"
                            class="cursorstyle"
                            @click.stop="toFans(item, '0')"
                          >
                            {{ item.fans }}</span
                          >
                          <span style="width:10px;display:inline-block"> </span>
                          <span @click.stop="toFans(item, '1')">
                            关注 &nbsp;
                          </span>

                          <span
                            style="color:#333333"
                            class="cursorstyle"
                            @click.stop="toFans(item, '1')"
                            >{{ item.follow }}
                          </span>
                          <span style="width:5px;display:inline-block"></span>
                          人气值 &nbsp;
                          <span style="color:#333333">{{ item.real_pop | numFomart}}</span>
                        </span>
                      </p>
                      <p class="three-four">
                        <span>
                          {{
                            item.introduction || "这个人很懒，什么都没写"
                          }}</span
                        >
                      </p>
                      <!-- <p class="three-two">{{ item.identity_text }}</p> -->
                      <p
                        style=" display: flex; justify-content: center"
                        v-if="user.id !== item.id"
                      >
                        <span
                          class="three-three cursorstyle"
                          @click.stop="follow(item)"
                          v-if="item.is_follow == 0"
                          >关注</span
                        >
                        <span
                          class="three-follow cursorstyle"
                          @click.stop="canclefollow(item)"
                          v-if="item.is_follow == 1"
                          >已关注</span
                        >
                      </p>
                    </div>
                  </div>
                </div></el-col
              >
            </el-row>
          </div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="bottomline"></div>

    <!-- appdown -->
    <div class="appdown cursorstyle" @click="todown">
      <p class="mobile"><i class="el-icon-mobile "></i></p>
      <span>APP</span>
      <div class="hide">
        <div class="er">
          <div class="ios" style="marginRight:10px">
            <img src="../../src/assets/img/iosDown.png" alt="" srcset="" />
            <p class="type">IOS</p>
          </div>
          <div class="android">
            <img src="../../src/assets/img/androidDown.png" alt="" srcset="" />
            <p class="type">Android</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import {
  shouindex,
  sms,
  followCreator,
  CanclefollowCreator,
  worklist,
  medialist,
  photoslist,
  videoList,
} from "@/api";
import { mapState } from "vuex";
export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      carouselSwiper: [],
      swiperBack: "",
      recommend: [],
      articleList: [],
      creator: [],
      newArticle: [],
      selectList: [],
      hover: false,
      user: "",
      circleMenu: ["听", "说", "读", "写"],
      stard: 270,
      stard_s: null, //用来默认储存第一个初始值
      avtiveCircle: 0,
      locationList: [
        { name: "郑州", id: "1" },
        { name: "开封", id: "2" },
        { name: "洛阳", id: "3" },
        { name: "平顶山", id: "4" },
        { name: "安阳", id: "8" },
        { name: "鹤壁", id: "6" },
        { name: "新乡", id: "7" },
        { name: "焦作", id: "5" },
        { name: "濮阳", id: "9" },
        { name: "许昌", id: "10" },
        { name: "漯河", id: "11" },
        { name: "三门峡", id: "12" },
        { name: "商丘", id: "15" },
        { name: "周口", id: "16" },
        { name: "驻马店", id: "17" },
        { name: "南阳", id: "13" },
        { name: "信阳", id: "14" },
        { name: "济源", id: "18" },
      ],
      locationActive: "1",
      tabFour: [
        { name: "看河南", type: "video", selecticon: "tab3", icon: "tab4" },
        { name: "读河南", type: "article", selecticon: "tab1", icon: "tab2" },
        { name: "赏河南", type: "atlas", selecticon: "tab5", icon: "tab6" },
        { name: "听河南", type: "audio", selecticon: "tab7", icon: "tab8" },
      ],
      fourCActive: "",
      fourVideo: [],
      fourArticle: [],
      fourAudio: [],
      fourAtlas: [],
      anmitionFlag: false,
      j: null,
    };
  },
  watch: {},
  computed: {
    ...mapState(["userInfo"]),
  },
  methods: {
    todown: function() {
      let fourF = this.$router.resolve({
        path: "/appdownload",
      });
      window.open(fourF.href, "_blank");
    },
    anmitionTime: function() {
      let a = 2;
      this.j = setInterval(() => {
        a--;
        //  this.anmitionFlag = !this.anmitionFlag
        console.log(a);
        if (a == 0) {
          this.anmitionFlag = false;
          clearInterval(this.j);
        }
      }, 500);
    },
    fourC: function(e) {
      // clearInterval(this.j)
      // this.anmitionFlag = true
      // this.fourCActive = e;
      setTimeout(() => {
        this.anmitionFlag = false;
      }, 500);
      // this.anmitionTime()
      // this.getFourList();
    },
    locatC: function(e) {
      this.locationActive = e.id;
      //  this.getFourList();
      let fourF = this.$router.resolve({
        path: "/itemlist",
        query: {
          id: this.locationActive,
        },
      });
      window.open(fourF.href, "_blank");
    },

    getFourList: function() {
      switch (this.fourCActive) {
        case "video":
          let fourOne = this.$router.resolve({
            path: "/videolist",
            query: {
              id: this.locationActive,
            },
          });
          window.open(fourOne.href, "_blank");
          break;
        case "article":
          let fourTwo = this.$router.resolve({
            path: "/articlelist",
            query: {
              id: this.locationActive,
            },
          });
          window.open(fourTwo.href, "_blank");
          break;
        case "atlas":
          let fourThree = this.$router.resolve({
            path: "/photolist",
            query: {
              id: this.locationActive,
            },
          });
          window.open(fourThree.href, "_blank");
          break;
        case "audio":
          let fourF = this.$router.resolve({
            path: "/medialist",
            query: {
              id: this.locationActive,
            },
          });
          window.open(fourF.href, "_blank");
          break;

        default:
          break;
      }
    },
    toAudioDetail: function(e) {
      const { href } = this.$router.resolve({
        path: "/mediadetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
      //  this.$router.push({
      //   path:'/mediadetail',
      //   query:{
      //     id:e
      //   }
      // })
    },
    tophoto: function(e) {
      // this.$router.push({
      //   path: "/photodetail",
      //   query: {
      //     id: e,
      //   },
      // });
      const { href } = this.$router.resolve({
        path: "/photodetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },
    toNewVideo: function(e) {
      //  this.$router.push({
      //   path: "/videodetail",
      //   query: {
      //     id: e,
      //   },
      // });
      const { href } = this.$router.resolve({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },
    toNewArticleDetail: function(e) {
      //  this.$router.push({
      //   path: "/articledetail",
      //   query: {
      //     id: e,
      //   },
      // });

      const { href } = this.$router.resolve({
        path: "/articledetail",
        query: {
          id: e,
        },
      });
      window.open(href, "_blank");
    },
    circleInit: function() {
      let box = document.querySelectorAll(".circle-box");
      console.log(box);
      let avd = 360 / box.length; //每一个 img-box 对应的角度
      let ahd = (avd * Math.PI) / 180; //每一个 img-box 对应的弧度
      let radius = 300 / 2; //圆的半径
      for (let i = 0; i < box.length; i++) {
        box[i].style.left = Math.sin(ahd * i) * radius + "px";
        box[i].style.top = Math.cos(ahd * i) * radius + "px";
      }
    },
    turn: function(index) {
      let _this = this;
      _this.stard = index * (360 / _this.circleMenu.length) + _this.stard_s;
    },
    toFans: function(e, type) {
      this.$router.push({
        path: "/fans",
        query: {
          id: e.id,
          type: type,
        },
      });
    },
    swiperTo: function(e) {
      window.open(e.link_url, "_blank");
      // switch (e.link_type) {
      //   // 外部
      //   case 1:
      //     if (e.link_url) {
      //       window.open(e.link_url, "_blank");
      //     }
      //     //  window.location.href='https://blog.csdn.net/qq_42221334/article/details/87531406'

      //     break;
      //   // 内部
      //   case 2:
      //     if (e.link_url) {
      //       this.$router.push({
      //         path: `/${e.link_url}`,
      //       });
      //     }

      //     break;

      //   default:
      //     break;
      // }
    },
    follow: function(e) {
      followCreator({
        creator: e.id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 1;
          e.fans++;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    canclefollow: function(e) {
      CanclefollowCreator({
        creator: e.id,
      }).then((res) => {
        if (res.code == 200) {
          e.is_follow = 0;
          if (e.fans > 0) {
            e.fans--;
          }
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //动态更改背景图片
    swiperchange: function(e) {
      this.swiperBack = this.carouselSwiper[e].vague_url;
    },
    tVideooDetail: function(e) {
      this.$router.push({
        path: "/videodetail",
        query: {
          id: e,
        },
      });
    },
    toArticleDetail: function(e) {
      this.$router.push({
        path: "/articledetail",
        query: {
          id: e,
        },
      });
    },

    toMe: function(e) {
      this.$router.push({
        path: "/creatorcenter",
        query: {
          id: e,
        },
      });
    },
    tivideoList: function() {
      this.$router.push({
        path: "/videolist",
      });
    },
    tiitemList: function() {
      let fourF = this.$router.resolve({
        path: "/itemlist",
      });
      window.open(fourF.href, "_blank");
    },
    toArticleList: function() {
      this.$router.push({
        path: "/articlelist",
      });
    },
    toCreatorList: function() {
      this.$router.push({
        path: "/creatorlist",
      });
    },
    toCatePage: function(e) {
      switch (e.type) {
        case 1:
          this.$router.push({
            path: "/mediadetail",
            query: {
              id: e.media_id,
            },
          });
          break;
        case 2:
          this.$router.push({
            path: "/videodetail",
            query: {
              id: e.media_id,
            },
          });
          break;
        case 3:
          this.$router.push({
            path: "/photodetail",
            query: {
              id: e.media_id,
            },
          });
          break;
        case 4:
          this.$router.push({
            path: "/articledetail",
            query: {
              id: e.media_id,
            },
          });
          break;

        default:
          break;
      }
    },
    moreContent: function(e) {
      const { href } = this.$router.resolve({
        path: "/itemlist",
        query: {
          type: e,
        },
      });
      window.open(href, "_blank");
    },
  },
  created() {
    this.stard_s = this.stard;
    if (Object.values(this.$store.state.userInfo.userinfo).length == 0) {
      let user = localStorage.getItem("user");
      if (user) {
        this.user = JSON.parse(user);
        this.$store.dispatch("userInfo/saveUserInfo", JSON.parse(user));
      }
    } else {
      this.user = this.$store.state.userInfo.userinfo;
    }

    // let user = localStorage.getItem("user");
    // if (user !== undefined) {
    //   this.user = JSON.parse(user);
    // }
  },
  mounted() {
    this.$nextTick(function() {
      // setTimeout(()=>{
      //     this.anmitionFlag = true
      // },5000)
      // this.fourCActive = e.type;
      // this.anmitionFlag = true
      setTimeout(() => {
        this.anmitionFlag = true;
      }, 1500);

      setTimeout(() => {
        this.anmitionFlag = false;
      }, 3000);
      // this.circleInit();
      shouindex().then((res) => {
        this.carouselSwiper = res.data.carousel;
        this.swiperBack = this.carouselSwiper[0].vague_url;
        this.recommend = res.data.recommend;
        this.articleList = res.data.article;
        this.creator = res.data.creator;
        this.newArticle = res.data.new;

        res.data.selected.map((item) => {
          item.hover = false;
        });
        this.selectList = res.data.selected.splice(0, 8);
        // this.getFourList();
        // this.anmitionTime()
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.swipermask::after {
  height: 400px;
}
.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.swiper {
  height: 400px;
  .swiper-box {
    width: 1200px;
    margin: 0 auto;
  }
}

.mainbox {
  // display: flex;
  background: #f7f7f7;
  .center {
    // flex: 1;
    width: 1200px;
    margin: 0 auto;
  }
}
.center {
  .circlemenu {
    width: 300px;
    height: 300px;
    border: 1px solid #000000;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .orgin {
      position: relative;
      width: 100px;
      height: 100px;
      transition: 0.5s;
      .circle-box {
        display: inline-block;
        position: absolute;
        width: 100px;
        height: 100px;
        background-color: orange;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.center {
  .centerone {
    .onetxt {
      display: flex;
      justify-content: space-between;
      padding-top: 60px;
      padding-bottom: 21px;
      align-items: baseline;
      .txt-l {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .txt-r {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background: #ffffff;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.imgflex {
  display: flex;
  align-items: center;
}
.card-box {
  .card-box-one {
    height: 160px;
    overflow: hidden;
    position: relative;
    .bg {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
    }
    .cat {
      position: absolute;
      bottom: 10px;
      // right: 60px;
    }
    .r60 {
      right: 60px;
    }
    .r10 {
      right: 10px;
    }
  }
  .card-box-two {
    margin-top: 20px;
    margin-left: 16px;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .card-box-three {
    margin-top: 10px;
    margin-left: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .line {
      margin-left: 12px;
    }
    span {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bebebe;
    }
  }
  .card-box-four {
    display: flex;
    padding: 11px 16px;
    align-items: center;
    .card-box-for-img {
      width: 24px;
      height: 24px;
    }
    .card-box-for-img img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
    .card-box-for-author {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 10px;
    }
    .card-box-for-job {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bebebe;
      margin-left: 16px;
    }
  }
  .card-box-five {
    text-indent: 2em;
    padding: 3px 16px;
    color: #bebebe;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.card-box-create {
  .creat-one {
    overflow: hidden;
  }
  .creat-one img {
    height: 120px;
  }
  .create-two {
    height: 200px;
  }
  .create-two-avatar {
    display: flex;
    justify-content: center;
    img {
      width: 82px;
      height: 82px;
      border-radius: 50%;
      margin-top: -41px;
      background-color: #fff;
    }
  }
  .create-three {
    text-align: center;
    .three-one {
      margin-top: 15px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
    .three-two {
      margin-top: 17px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bebebe;
    }
    .three-four {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #bebebe;
      margin-top: 10px;
      span {
        display: inline-block;
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
      }
    }
    .three-three {
      margin-top: 16px;
      display: block;
      width: 60px;
      line-height: 26px;
      background: #e35111;
      color: #ffffff;
      border-radius: 2px;
    }
    .three-follow {
      margin-top: 16px;
      display: block;
      width: 60px;
      line-height: 26px;
      color: #333333;
      border: 1px solid #dedede;
      border-radius: 2px;
    }
  }
}

.swiperimage {
  width: 100%;
}
.bottomline {
  background: #f7f7f7;
  height: 50px;
}
.fourActive {
  background: url("../assets/img/henan/noactive.png") no-repeat;
  color: #cf8f00;
}
.fourNoActive {
  background: url("../assets/img/henan/active.png") no-repeat;
  color: #fff;
}
.henan {
  background: url("../assets/img/henan/bg.jpeg") no-repeat;
}

.fourtxt {
  color: #fff;
}
.fourop {
  opacity: 0.6 !important;
  border-top: 50px solid #e35111 !important;
}
.fourborderColoe {
  background: #e35111 !important;
}
.newCenter {
  width: 1200px;
  margin: 0 auto;
  height: 500px;
  .tab-box {
    display: flex;
    padding: 90px 0;
    align-items: center;
    .tab-c {
      flex: 1;
      display: flex;
      align-items: center;
      // justify-content: center;
      justify-content: space-around;
      .item {
        width: 159px;
        height: 43px;
        line-height: 43px;
        font-family: cursive;
        p {
          text-align: center;
          font-size: 28px;
          font-weight: 400;
        }
        .photo {
          // width: 15px;
          // height: 14px;
        }
      }
    }
    .tab-l {
      width: 179px;
    }
  }
  .newCente-one {
    height: 250px;
    .item {
      position: relative;

      // display: flex;
      .item-box {
        width: 111px;
        position: absolute;
        height: 112px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover .list {
          background: #e35111 !important;
        }
        &:hover .op {
          opacity: 0.6 !important;
          border-top: 50px solid #e35111 !important;
        }

        //  border-image: url('https://www.runoob.com/try/demo_source/border.png') 30  round;
        .list {
          box-sizing: border-box;
          transform: rotate(45deg);
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          // border: 5px solid transparent;
          transition: all 0.3s;

          border-style: solid;
          border-width: 6px;
          border-image: url("../assets/img/henan/border.png") 5 round;
        }
        .photo {
          width: 112px;
          transform: rotate(-45deg);
        }
      }
      .txt {
        position: absolute;
        font-size: 16px;
        font-family: "PingFang SC", "Microsoft YaHei", "微软雅黑";
        font-weight: 600;

        // -webkit-text-stroke: 0.4px #ffffff;
        // color: #e35111;
        // -webkit-text-stroke: 0.4px #ffffff;
        color: #ffff;
        z-index: 17;
        top: 53%;
        //  &:hover + .list{
        //      background: #e35111 !important;
        //    }
      }
      .op {
        position: absolute;
        width: 0;
        height: 0;
        border-left: 50px solid transparent;
        border-right: 50px solid transparent;
        opacity: 0.32;
        border-top: 50px solid rgb(0, 0, 0);
        top: 50%;
        // &:hover + .list{
        //      background: #e35111 !important;
        //    }
      }
    }

    .item-box:nth-child(2n) {
      top: 65px;
    }
  }
}
.listbox {
  // yin
  .audiobox {
    background-color: #fff;
    .item-box {
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      .item {
        width: 20%;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        .item-one {
          img {
            // width: 100%;
            width: 100%;
            height: 140px;
            border-radius: 8px;
          }
        }
        .item-two {
          margin-top: 20px;
          p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
        }
        .item-three {
          margin-top: 14px;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .fourPhoto {
    .photo-box {
      // width: 960px;
      // margin: 0 auto;
      .grid-content {
        border-radius: 4px;
        min-height: 36px;
        background: #ffffff;
      }
      .card-box {
        overflow: hidden;
        .card-box-one {
          height: 150px;
          padding: 11px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          // img {
          //   // width: 100%;
          //   // height: 100%;
          //   // max-width: 100%;
          //   // max-height: 100%;
          // }
        }
        .card-box-two {
          margin-top: 20px;
          margin-left: 16px;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .card-box-three {
          margin-top: 10px;
          margin-left: 16px;
          .line {
            margin-left: 12px;
          }
          span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bebebe;
          }
        }
        .card-box-four {
          display: flex;
          padding: 11px 16px;
          align-items: center;
          .card-box-for-img {
            width: 24px;
            height: 24px;
          }
          .card-box-for-img img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
          .card-box-for-author {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 10px;
          }
          .card-box-for-job {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bebebe;
            margin-left: 16px;
          }
        }
        .card-box-five {
          text-indent: 2em;
          padding: 3px 16px;
          color: #bebebe;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .card-box-play {
          margin-left: 16px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          .play {
            width: 12px;
          }
          span {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bebebe;
          }
        }
      }
    }
  }
}

// 动画效果
.newCenter {
  .newCente-one {
    .anmitionList {
      // animation: box 500ms ease;
      animation-name: box;
      animation-duration: 200ms;

      //         -webkit-backface-visibility: hidden;
      // -webkit-transform-style: preserve-3d;
      // animation-delay: 3s;
    }
  }
}

@keyframes box {
  0% {
    // background: #000000 !important;
    // background: #2b211d;
    transform: rotate(90deg);
  }
  50% {
    // background: #e35111 !important;
    background: #e35111;
    transform: rotate(45deg);
  }
  100% {
    // background: orange !important;
    // background: #e35111 ;
    transform: rotate(45deg);
  }
}

.hovereffect {
  background-color: rgba(0, 0, 0, 0.4);
  height: 193px !important;
  .articleShow {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 0;
    position: absolute;
    transition: all 0.25s;
    overflow: hidden;

    p {
      color: #fff;
      padding: 18px 30px;
      font-size: 14px;
    }
  }
  &:hover .articleShow {
    height: 100%;
  }
}

.appdown {
  padding: 10px;
  position: fixed;
  bottom: 10%;
  right: 5%;
  border: 1px solid #f1f1f1;
  background-color: #ffffff;
  box-shadow: 3px 2px 3px#f5f5f5;
  .mobile {
    text-align: center;
    font-size: 20px;
  }
  &:hover .hide {
    display: block;
  }
  .hide {
    display: none;
  }
  .er {
    display: flex;
    width: 200px;
    height: 100px;
    // background-color: orange;
    position: absolute;
    right: 120%;
    top: 0;
    img {
      max-width: 100%;
    }
    .type {
      text-align: center;
    }
  }
}
</style>
