<template>
  <div class="footer">
    <div class="main-one">
      <div class="main-left">
        <div class="left-one">
          <div class="logobox">
            <div class="logo">
              <img src="../assets/img/logo.png" alt="" style=" width: 100%;"/>
            </div>
            <div class="logotxt">
               <span class="logoname">河南文化传播交流平台</span>
            </div>
          </div>
        </div>
      </div>
      <div class="main-right">
          <p><span v-for="(item,x) in list" :key='x' class="pathtxt cursorstyle" @click="toPath(item.path)">{{item.name}}</span></p>
          <br/>
          <p class="othertxt">备案号：豫ICP备2020031481号-5</p>
          <p class="othertxt">豫网文：(2021)3882-169号</p>
          <!-- <p class="othertxt">备案号：豫ICP备2020031481号-3  |  增值电信业务经营许可证：豫ICP备2020031481号-3 </p>
          <p class="othertxt">豫公网安备：44030002000001号  |  豫网文：[2017]6138-1456号</p> -->
      </div>
    </div>
      <!-- <p class="othertxt">豫公网安备：44030002000001号  |  豫网文：[2017]6138-1456号</p> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      list: [
        { name: "首页", path: "" },
        { name: "| 多彩河南", path: "" },
        { name: "| 创作人", path: "creatorlist" },
        { name: "| 河南高校影视教育协会", path: "school" },
        { name: "| 中原广告研究院", path: "adschool" },
        { name: "| App下载", path: "appdownload" },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    toPath:function(e){
      //  this.$router.push(`/${e}`);
       let fourF = this.$router.resolve({
            path: `${e}`,
        });
        window.open(fourF.href, "_blank");
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.footer {
  .main-one {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 61px;
    padding-bottom: 51px;
    .main-left {
      .logo{
        width: 200px;
            margin-right: 20px;
        // height: 42px;
      }
      .logotxt{
        // margin-top: 15px;
        text-align: center;
        font-size: 16px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #333333;
      }
      .logobox{
        display: flex;
        align-items: center;
      }
    }
    .main-right{
          padding-left: 60px;
          display: flex;
          flex-direction: column;
          p{
            flex: 1;
          }
    // padding-top: 10px;
      .pathtxt{
        font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
    margin-right: 5px;
color: #333333;
      }
      .othertxt{
        font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #999999;
 
      }
    }

  }

}

</style>
