<template>
  <div id="app">
    <!-- 主要显示的内容 -->
    <div v-if="$route.meta.keepAlive" class="main-content">
      <Header v-if="w"></Header>
      <router-view />
      <Footer v-if=w></Footer>
    </div>
    <!-- 不需要显示登陆页面的内容 -->
    <div v-if="!$route.meta.keepAlive" class="h">
      <router-view />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
// import SOCKET from "@/components/socket.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },
  data(){
    return {
      color:'',
      w:true
    }
  },
  created(){
    this.$sock.sockinit()
    var w = document.body.clientWidth
    this.w = w < 768 ? false : true
    // 在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem('store'))
          )
        )
       // console.log(sessionStorage.getItem('store'))  // 打印
        // this.$store.dispatch("userInfo/saveUserInfo", es.data.userinfo);
      }
 
      // 在页面刷新时将vuex里的信息保存到sessionStorage里
      // beforeunload事件在页面刷新时先触发
      window.addEventListener('beforeunload', () => {
        console.log(this.$store.state)
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
        // console.log(JSON.parse(sessionStorage.getItem('store')))  // 打印
      })

  },
  
  // watch: {
  //   $route(to, from) {
  //     console.log(to.path);
  //     if (to.path == "/upload") {
  //       this.color = "#ffffff"
  //     }
  //   },
  // },
};
</script>
<style>
html,body,#app,.hmain-content,.h{
  height: 100% !important;
}
* {
  margin: 0;
}





</style>
